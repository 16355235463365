function reformatDate(s) {
  if (typeof s == "string") {
    const [day, month, year] = s.split("-");
    return new Date(year, month, day);
  } else {
    return s;
  }
}

function notEmpty(l) {
  return l?.length > 0;
}

function isNothing(o) {
  return o === undefined || o === null;
}

const repeat = (arr, n) => [].concat(...Array(n).fill(arr));

export { reformatDate, notEmpty, repeat, isNothing };
