import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import useWindowDimensions from "./useWindowDimensions";

const useIsVertical = (maxWidth = 800) => {
  const [isVertical, setIsVertical] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setIsSmall(width < maxWidth);
  }, [width]);
  useEffect(() => {
    setIsVertical(isMobile || isSmall);
  }, [isSmall, isMobile]);

  return { isVertical };
};

export { useIsVertical };
