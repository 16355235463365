import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { TagList } from "./Tag";
import ClientList from "./ClientList";
import SmallTimeLine from "./SmallTimeLine";

function ProjectBlockMedia({ item, hide, isHorizontal = true, width = 300 }) {
  const [height, setHeight] = useState(0);
  const ref = useRef();

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [setHeight, ref]);

  return (
    <div
      style={{ width: isHorizontal ? 1000 : width }}
      className={classNames(
        "shadow-lg flex-col my-3 border-2 rounded",
        hide ? "hidden" : ""
      )}
    >
      <div
        className={classNames(
          "flex items-center content-center justify-center",
          isHorizontal ? "flex-row" : "flex-col"
        )}
      >
        <img
          src={item.media.side}
          alt={item.title}
          style={{
            width: isHorizontal ? 380 : width,
            height: isHorizontal ? height : "auto",
          }}
          className={"object-cover"}
        />
        <div
          className={"flex flex-col px-4 py-2 bg-white"}
          style={{ width: isHorizontal ? 620 : width }}
          key={item.id}
          ref={ref}
        >
          <div className={"flex flex-row justify-between"}>
            <TagList item={item} />
            <ClientList item={item} />
          </div>
          <div
            className={classNames(
              "flex mt-4",
              Object.entries(item.media.attachments).length > 4
                ? "flex-col"
                : "flex-row "
            )}
          >
            <SmallTimeLine timeFrames={item.timeFrames} />
            <div className={"flex text-xl flex-wrap "}>
              {Object.entries(item.media.attachments).map(([key, value]) => (
                <button
                  className={
                    "border-2 rounded px-2 mr-2 my-2 hover:bg-gray-100 text-sm border-gray-300"
                  }
                  onClick={() => window.open(value, "_blank").focus()}
                  key={key}
                >
                  {key}
                </button>
              ))}
            </div>
          </div>

          <div className={"text-xl pt-3 "}>{item.title}</div>
          <div className={"text-sm italic  text-gray-500"}>{item.subTitle}</div>
          <div className={"italic text-sm font-thin pb-2"}>
            {item.role.length > 1 ? "roles: " : "role: "}
            {item.role.map((item, index) => (
              <span className={"font-light text-gray-500"} key={index}>
                {item}
              </span>
            ))}
          </div>
          <div className={"text-gray-700"}>{item.description}</div>
        </div>
      </div>
    </div>
  );
}

export default ProjectBlockMedia;
