import { useHighlightContext } from "../context/HighlightContext";
import classNames from "classnames";
import { TagList } from "../components/Tag";
import ClientList from "../components/ClientList";
import ReactPlayer from "react-player";

function ContentPage() {
  const { highlight } = useHighlightContext();

  return (
    <div
      className={classNames(
        "flex content-center justify-center",
        "text-left",
        "mx-10 mt-10",
        "overflow-y-scroll"
      )}
    >
      {highlight ? (
        <>
          <div className={"flex flex-col px-4 max-w-prose pr-6"}>
            <div className={"text-xl"}>{highlight.title}</div>
            <div className={"text-sm italic pb-2 text-gray-500"}>
              {highlight.subTitle}
            </div>
            <div className={"text-gray-700"}>{highlight.description}</div>
            {highlight.media.video ? (
              <div className={"max-w-prose mr-2"}>
                <ReactPlayer url={highlight.media.video} controls />
              </div>
            ) : (
              []
            )}
            <div
              className={classNames(
                "flex mt-4  ",
                Object.entries(highlight.media.attachments).length > 4
                  ? "flex-col"
                  : "flex-row "
              )}
            >
              {/*<SmallTimeLine timeFrames={highlight.timeFrames} />*/}
              <div className={"flex text-xl flex-wrap"}>
                {Object.entries(highlight.media.attachments).map(
                  ([key, value]) => (
                    <button
                      className={
                        "border-2 rounded px-2 mr-2 my-2 hover:bg-gray-100 text-sm border-gray-300"
                      }
                      onClick={() => window.open(value, "_blank").focus()}
                      key={key}
                    >
                      {key}
                    </button>
                  )
                )}
              </div>
            </div>
            <ClientList item={highlight} />
          </div>
          <div className={"flex flex-col w-96 "}>
            {highlight.media.sideIsVideo ? (
              <video
                className="object-contain"
                autoPlay={true}
                muted
                key={highlight.id}
              >
                <source src={highlight?.media.side} type="video/mp4" />
              </video>
            ) : (
              <img
                src={highlight.media.side}
                alt={highlight.title}
                className={"object-contain"}
              />
            )}

            <TagList item={highlight} />
            <div className={"italic text-sm font-thin pt-2"}>
              {highlight.role.length > 1 ? "roles: " : "role: "}
              {highlight.role.map((item, index) => (
                <span className={"font-light"} key={index}>
                  {item}
                </span>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className={"italic text-xl pt-10 font-base text-gray-800"}>
          Click on any item of the timeline to view more details
        </div>
      )}
    </div>
  );
}

export default ContentPage;
