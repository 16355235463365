import { useFilterContext } from "../context/FilterContext";
import { useIsVertical } from "../hooks/useIsVertical";
import ProjectBlockMedia from "../components/ProjectBlockMedia";
import MobileBlock from "../components/MobileBlock";
import classNames from "classnames";

function PortfolioListPage({ list, title, text }) {
  const { activeExperienceList } = useFilterContext();
  const activeIds = activeExperienceList.map((item) => item.id);
  const { isVertical } = useIsVertical();

  const anyActiveItems = list
    ? list.map((item) => activeIds.includes(item.id)).some((item) => item)
    : false;

  return (
    <div
      className={classNames(
        isVertical ? "mx-6" : "h-screen overflow-y-scroll overflow-hidden"
        // "content-center items-center object-center"
      )}
    >
      <div
        className={classNames(
          "text-left mt-4  ",
          isVertical ? "pt-4" : "mx-20 pt-20"
        )}
      >
        <div className={"text-xl font-bold pt-4"}>{title}</div>
        <div className={"text-gray-700 pb-3 max-w-2xl "}>{text}</div>
        <div className={"flex flex-col shrink"}>
          {anyActiveItems ? (
            list
              .reverse()
              // .splice(0, 4)
              .map((item, index) =>
                isVertical ? (
                  <MobileBlock
                    item={item}
                    key={index}
                    hide={!activeIds.includes(item.id)}
                  />
                ) : (
                  <ProjectBlockMedia
                    item={item}
                    key={index}
                    hide={!activeIds.includes(item.id)}
                    isHorizontal={!isVertical}
                    maxWidth={isVertical ? 400 : 1000}
                  />
                )
              )
          ) : (
            <div className={"italic text-sm"}>
              ...No items found for these filters
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PortfolioListPage;
