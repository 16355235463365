import "./App.css";
import SideBar from "./components/SideBar";
import GraphContainer from "./components/GraphContainer";
import ContentPage from "./pages/ContentPage";
import { useIsVertical } from "./hooks/useIsVertical";
import SideBarMobile from "./components/SideBarMobile";
import { useState } from "react";

/**
 * TODO: check each item if all fields are alright
 */

function App() {
  const { isVertical } = useIsVertical();
  const [showPortfolioList, setShowPortfolioList] = useState(false);
  return (
    <div className="App ">
      {isVertical ? (
        <SideBarMobile />
      ) : (
        <div className={"flex flex-row h-screen w-screen "}>
          <SideBar
            showPortfolioList={showPortfolioList}
            setShowPortfolioList={setShowPortfolioList}
          />

          {!showPortfolioList && (
            <div className={"flex grow flex-col overflow-x-scroll"}>
              {/*<Header />*/}

              <div className={"flex flex-col overflow-auto"}>
                <GraphContainer />
                <ContentPage />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
