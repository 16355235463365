import { useDataContext } from "../context/DataContext";
import { useEffect, useState } from "react";
import Button from "./Button";
import classNames from "classnames";
import { Done } from "@mui/icons-material";
import { useFilterContext } from "../context/FilterContext";

const Checkbox = ({
  label,
  value,
  onChange = () => {},
  size = 16,
  colorClass = "swaabit-sea",
  checkColor = "swaabit-sun",
  borderSize = 1,
  borderColor = "gray-900",
}) => {
  const onClick = () => {
    onChange(label);
  };

  return (
    <div
      className={classNames(
        "font-light text-sm flex flex-row items-center cursor-pointer",
        "hover:bg-" + colorClass,
        "hover:bg-opacity-10"
      )}
      onClick={() => {
        onClick();
      }}
    >
      <div
        className={classNames(
          "border-2 rounded-sm cursor-pointer flex items-center justify-center content-center",
          "border-" + (value ? colorClass : borderColor),
          value ? "bg-" + colorClass : ""
        )}
        style={{ width: size, height: size, borderWidth: borderSize }}
      >
        {value && (
          <Done
            sx={{ fontSize: size - 2, strokeWidth: 2 }}
            className={classNames("stroke-" + checkColor, "text-" + checkColor)}
            // style={{ marginLeft: -1, marginTop: -1 }}
          />
        )}
      </div>
      <div className={"pl-1 pr-4"}>{label}</div>
    </div>
  );
};

function FilterList({ experienceKey = "" }) {
  const { data } = useDataContext();
  const { filters, selectFilter } = useFilterContext();
  const [filterNameList, setFilterNameList] = useState([]);

  const [showAllFilter, setShowAllFilter] = useState(false);
  const basicHeight = filterNameList.length === 3 ? 85 : 105;
  useEffect(() => {
    if (experienceKey in filters) {
      const items = data?.experience
        ? data?.experience
            .map((experience) => {
              const val = experience[experienceKey];
              if (typeof val == "string") {
                return [val];
              }
              return val;
            })
            .flat()
        : [];

      const itemsFormatted = items.map((s) => s.toLowerCase());

      const itemCountMap = itemsFormatted.reduce(
        (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
        new Map()
      );

      const sortedMap = new Map(
        [...itemCountMap].sort((a, b) => {
          return b[1] - a[1];
        })
      );

      let sortedList = [];
      sortedMap.forEach(function (value, key) {
        sortedList.push(key);
      });

      const activeFilters = [];
      const inActiveFilters = [];

      sortedList
        .filter((item) => item !== undefined && item !== null && item !== "")
        .forEach((item) =>
          filters[experienceKey][item]
            ? activeFilters.push(item)
            : inActiveFilters.push(item)
        );
      setFilterNameList([...activeFilters, ...inActiveFilters]);
    }
  }, [data, experienceKey, filters]);

  return (
    <div>
      <div
        style={{ height: showAllFilter ? "auto" : basicHeight }}
        className={"mt-4 overflow-hidden relative"}
      >
        <div className={" text-base"}>
          {experienceKey.charAt(0).toUpperCase() + experienceKey.slice(1)}
        </div>
        <div className={"flex flex-col h-full "}>
          {filterNameList.map((item, index) => (
            <Checkbox
              size={12}
              label={item}
              key={index}
              onChange={(val) => selectFilter(val, experienceKey)}
              value={filters[experienceKey][item]}
            />
          ))}
        </div>
        {filterNameList.length > 4 && (
          <Button
            onClick={() => setShowAllFilter(!showAllFilter)}
            className={
              "absolute bottom-0 right-0 text-swaabit-sea shadow-none hover:bg-swaabit-blue hover:bg-opacity-10"
            }
          >
            {showAllFilter ? "< less" : "all >"}
          </Button>
        )}
      </div>
    </div>
  );
}

export default FilterList;
