import { useTimeLine } from "../hooks/useTimeLine";
import classNames from "classnames";
import { useState } from "react";
import { useHighlightContext } from "../context/HighlightContext";

function TimeLineTimeBar({ details, style, color, background }) {
  const { timeFrameToTimeLine } = useTimeLine();
  const { setHighlight, isHighlight } = useHighlightContext();

  const positions = details?.timeFrames.map((timeFrame) =>
    timeFrameToTimeLine(timeFrame)
  );
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={classNames("relative")}
      style={style}
      onClick={() => setHighlight(details)}
      key={details.id}
    >
      {positions.map(([startPos, endPos], index) => (
        <div
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={classNames(
            isHighlight(details.id) ? "shadow-2xl shadow-amber-300 " : "",
            isHighlight(details.id) ? "bg-swaabit-red3" : color,
            "absolute border-2 border-white bottom-0 text-xs italic",
            "whitespace-nowrap cursor-pointer",
            "hover:z-20",
            "px-2 py-1",
            "overflow-hidden"
          )}
          style={{
            left: startPos || 0,
            width:
              isHover && endPos - startPos < 140 // only enlarge small items on hover
                ? "auto"
                : endPos - startPos || 0,
          }}
          key={index}
        >
          <div className={"absolute top-0 left-0 -mt-2 hover:overflow-hidden"}>
            {background}
          </div>
          <div
            className={classNames(
              "relative",
              // "-rotate-45 origin-bottom-left",
              " text-left"
              // "pl-1 pt-2"
            )}
          >
            {details.title}
          </div>
        </div>
      ))}
    </div>
  );
}

export default TimeLineTimeBar;
