import {
  FilterList as FilterListIcon,
  FilterListOff,
} from "@mui/icons-material";
import { useFilterContext } from "../context/FilterContext";
import { useEffect, useRef, useState } from "react";
import FilterList from "./FilterList";
import { useIsVertical } from "../hooks/useIsVertical";
import classNames from "classnames";
import useScrollPosition from "../hooks/useScrollPosition";

function FilterSection() {
  const { filters, textSearch, setTextSearch } = useFilterContext();
  const filterClick = () => setShowFilters(!showFilters);
  const { isVertical } = useIsVertical();
  const [showFilters, setShowFilters] = useState(true);
  const ref = useRef();
  const scrollPosition = useScrollPosition();
  const [isAtTop, setIsAtTop] = useState(false);
  const [filterPosition, setFilterPosition] = useState();

  useEffect(() => {
    if (isVertical) {
      setShowFilters(false);
    }
  }, [isVertical]);

  useEffect(() => {
    if (ref.current.getBoundingClientRect().top < 0) {
      setFilterPosition(scrollPosition);
      setIsAtTop(true);
    }
    if (scrollPosition <= filterPosition) {
      setIsAtTop(false);
    }
  }, [scrollPosition]);

  return (
    <div
      className={classNames(
        "text-left pt-4 pb-4 ",
        isVertical ? "" : "h-80",
        isAtTop ? "fixed top-0 left-0 right-0 bg-swaabit-sun px-6 z-50" : ""
      )}
      ref={ref}
    >
      <div className={"flex flex-row justify-between"}>
        <div className={"text-lg"}>Filters</div>
        {showFilters ? (
          <FilterListOff onClick={filterClick} />
        ) : (
          <FilterListIcon onClick={filterClick} />
        )}
      </div>

      <input
        className={"bg-transparent border-b-2 border-swaabit-sea mt-2"}
        placeholder={"search: gaming"}
        onChange={(event) => setTextSearch(event.target.value)}
        value={textSearch}
      />
      {showFilters && filters && (
        <div
          className={classNames(
            "pb-6",
            isVertical
              ? "h-screen overflow-y-scroll"
              : "h-full overflow-y-scroll"
          )}
        >
          {isVertical && <FilterList experienceKey={"category"} />}
          <FilterList experienceKey={"themes"} />
          <FilterList experienceKey={"platforms"} />
          <FilterList experienceKey={"skills"} />
          <FilterList experienceKey={"tools"} />
          <FilterList experienceKey={"role"} />
        </div>
      )}
    </div>
  );
}

export default FilterSection;
