import TimeLineTimeBar from "./TimeLineTimeBar";
import DashedLine from "./DashedLine";
import { isEmpty } from "lodash";
import classNames from "classnames";

function buildTree(nodesToParent) {
  nodesToParent = nodesToParent.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  let tree = {};
  Object.entries(nodesToParent).forEach(([id, parent]) => {
    // if the parent doesnt exist anymore due to filtering, the item should become parent by itself
    if (parent in Object.keys(nodesToParent)) {
      parent = "";
    }
    if (parent in tree) {
      tree[parent].push(id);
    } else {
      tree[parent] = [id];
    }
  });
  return tree;
}

function buildLevels(tree, baseIdList, counter = 0) {
  const nextIds = baseIdList
    ? baseIdList
        .map((id) => (id in tree ? tree[id] : null))
        .flat()
        .filter((item) => item !== null)
    : [];
  if (isEmpty(nextIds)) {
    return counter === 0 ? [baseIdList] : baseIdList;
  } else {
    return [baseIdList, buildLevels(tree, nextIds, counter + 1)];
  }
}

function TimeLineTimBarContainer({ category, color, experiences }) {
  const idToExperience = Object.fromEntries(
    experiences.map((experience) => [experience.id, experience])
  );

  const tree = buildTree(
    experiences.map((item) => ({
      key: item.id,
      value: item.parent,
    }))
  );

  return (
    <div className={"h-7 flex flex-row relative"}>
      <div
        key={"category"}
        className={classNames(
          "fixed",
          "text-sm italic self-center text-gray-500 bg-white z-30 text-right",
          "w-28 pr-1 rounded",
          "border-2 ",
          "border" + color
        )}
        style={{ left: 216 }}
      >
        {category}
      </div>
      {!isEmpty(tree) &&
        buildLevels(tree, tree[""])
          .filter((row) => !isEmpty(row))
          .map((row, rowIndex) => {
            return row.map((id, index) =>
              id in idToExperience ? (
                <TimeLineTimeBar
                  details={idToExperience[id]}
                  key={id + index}
                  color={"bg" + color}
                  background={
                    rowIndex > 0 ? (
                      <DashedLine
                        dashLength={1.5}
                        dashThickness={50}
                        dashGap={8}
                        dashColor={"bg" + color + "2"}
                        repetitions={140}
                        rotationDegrees={45}
                        transformOrigin={"top"}
                        style={{ zIndex: 30 }}
                      />
                    ) : (
                      []
                    )
                  }
                />
              ) : (
                <></>
              )
            );
          })}
    </div>
  );
}

export default TimeLineTimBarContainer;
