import classNames from "classnames";
import DashedLine from "./DashedLine";
import { useHighlightContext } from "../context/HighlightContext";

function DetailView({ experience, isUpper, onClick, width, margin }) {
  const { isHighlight } = useHighlightContext();

  const stick = (
    <DashedLine
      vertical={true}
      dashGap={3}
      dashLength={3}
      dashThickness={2}
      dashColor={isHighlight(experience.id) ? "bg-swaabit-red" : "bg-gray-400"}
      repetitions={8}
      key={"dash"}
    />
  );
  // const stick = <div key="stick" className={"w-2 h-10  bg-blue-300"}></div>;
  const media = Object.values(experience.media).filter(
    (val) => val !== null && val !== undefined && val !== ""
  );

  const box = (
    <div
      key="box"
      className={classNames(
        "h-30 shadow-lg border-2 flex flex-col flex-shrink-0 bg-white overflow-y-clip relative cursor-pointer",
        isHighlight(experience.id)
          ? "shadow-swaabit-red3 border-swaabit-red3"
          : ""
      )}
      style={{ width }}
    >
      {media.sideIsVideo ? (
        <video
          src={experience?.media.side}
          className="object-cover h-16"
          muted
          key={experience.id}
        />
      ) : (
        <img
          src={experience?.media.side}
          className="object-cover h-16"
          alt={experience.title}
        />
      )}

      <div
        className={"flex flex-shrink-0 text-sm z-20 h-14 p-2 place-self-center"}
      >
        {experience.title}
      </div>
      {/*<div className={"flex text-xs max-h-14  "}>{experience.description}</div>*/}
    </div>
  );

  return (
    <div
      className={"flex flex-col items-center"}
      style={{ width, marginLeft: margin, marginRight: margin }}
      onClick={onClick}
    >
      {isUpper ? [box, stick] : [stick, box]}
    </div>
  );
}

function GraphDetailsSection({ experiences, isUpper, setPageContent }) {
  return (
    <div className={classNames("flex flex-row", isUpper ? "items-end" : "")}>
      {experiences
        ?.filter((details) => details.isProject)
        .map((details) => (
          <DetailView
            experience={details}
            isUpper={isUpper}
            onClick={() => setPageContent(details)}
            key={details.id}
          />
        ))}
    </div>
  );
}

export { GraphDetailsSection, DetailView };
