import React from "react";
import classNames from "classnames";

const Button = ({
  id,
  children,
  onClick,
  className,
  style,
  disabled,
  type,
  ref,
}) => {
  const disabledClassNames = disabled ? "disabled" : "";
  return (
    <button
      ref={ref}
      id={id}
      className={classNames(
        "shadow-md px-2 py-.5  hover:bg-gray-100",
        disabledClassNames,
        className
      )}
      style={style}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      <span>{children}</span>
    </button>
  );
};

export default Button;
