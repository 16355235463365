import classNames from "classnames";

function Tag({ text, color }) {
  return (
    <div className={classNames("rounded-2xl px-2 text-xs mr-1 mt-1", color)}>
      #{text}
    </div>
  );
}

function TagList({ item }) {
  const keyList = ["keyWords", "skills", "tools", "platforms"];
  const colorList = [
    "bg-swaabit-yellow",
    "bg-swaabit-sky",
    "bg-swaabit-sun",
    "bg-swaabit-blue",
  ];
  return (
    <div className={"flex flex-row  content-center flex-wrap flex-shrink "}>
      {keyList
        .map((key, keyIndex) =>
          key in item
            ? item[key].map((word, index) => (
                <Tag
                  text={word}
                  key={index + word}
                  color={colorList[keyIndex]}
                />
              ))
            : []
        )
        .flat()}
    </div>
  );
}

export { Tag, TagList };
