import { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { notEmpty } from "../utils/time";
import { useFilterContext } from "./FilterContext";
import { MIN_YEAR_WIDTH, TOTAL_PROJECT_SIZE } from "../utils/constants";

const TimeLineContext = createContext({
  baseTimeLine: {}, // year: width
  firstYear: 2000,
  lastYear: 2023,
});

const useTimeLineContext = () => {
  // get the context
  return useContext(TimeLineContext);
};

function calcBaseTimeLine(firstYear, lastYear, projects, timeBarExperiences) {
  // timeBarExperiences.filter((experience) =>
  //   experience.timeFrames.map((timeFrame) => {
  //     const val = timeFrame.ended - timeFrame.started;
  //   })
  // );
  let yearList = _.range(firstYear, lastYear + 1, 1);
  const yearWidths = () => {
    let lastPosition = 0;
    return yearList.map((year) => {
      const projectCount = projects?.filter(
        (item) => item.timeFrames[0].started.getFullYear() === year - 1
      ).length;
      lastPosition =
        projectCount === 0
          ? lastPosition + MIN_YEAR_WIDTH
          : lastPosition + projectCount * TOTAL_PROJECT_SIZE;
      return lastPosition;
    });
  };
  return _.zipObject(yearList, yearWidths());
}

const TimeLineProvider = ({ children }) => {
  const [baseTimeLine, setBaseTimeLine] = useState({});
  const { activeProjectList, activeExperienceList } = useFilterContext();
  const [firstYear, setFirstYear] = useState(2000);
  const [lastYear, setLastYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (notEmpty(activeExperienceList)) {
      const first = activeExperienceList[0].timeFrames[0].started.getFullYear();
      const allTimeFrames = activeExperienceList
        .map((experience) =>
          experience.timeFrames.map((timeFrame) => timeFrame.ended)
        )
        .flat();
      const lastEnded = new Date(Math.max.apply(null, allTimeFrames));
      const last = lastEnded.getFullYear() + 1;
      setFirstYear(first);
      setLastYear(last);

      // Create a list of non project experiences to increase the timeline for certain years if the width is very small
      // Not worth the effort for now
      // const timeBarExperiences = activeExperienceList
      //   ?.filter((details) => !details.isProject)
      //   .filter((item) => !item.isSinglePoint || item.isParent);
      setBaseTimeLine(calcBaseTimeLine(first, last, activeProjectList));
    }
  }, [activeExperienceList, activeProjectList]);

  return (
    <TimeLineContext.Provider value={{ baseTimeLine, firstYear, lastYear }}>
      {children}
    </TimeLineContext.Provider>
  );
};

export { useTimeLineContext, TimeLineProvider };
