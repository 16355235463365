const PROJECT_WIDTH = 150;
const PROJECT_HEIGHT = 50;
const MIN_YEAR_WIDTH = 100;
const PROJECT_MARGIN = 10;
const MIN_HIGHLIGHT_WIDTH = 50 / 6;
const TOTAL_PROJECT_SIZE = PROJECT_WIDTH + 2 * PROJECT_MARGIN;

export {
  PROJECT_WIDTH,
  PROJECT_MARGIN,
  MIN_YEAR_WIDTH,
  MIN_HIGHLIGHT_WIDTH,
  PROJECT_HEIGHT,
  TOTAL_PROJECT_SIZE,
};
