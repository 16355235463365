import { createContext, useContext, useState } from "react";

const HighlightContext = createContext({
  highlight: {},
  setHighlight: () => {},
  isHighlight: () => {},
});

// context consumer hook
const useHighlightContext = () => {
  // get the context
  return useContext(HighlightContext);
};

const HighlightProvider = ({ children }) => {
  const [highlight, setHighlight] = useState();
  const isHighlight = (id) => id === highlight?.id;

  return (
    <HighlightContext.Provider value={{ highlight, setHighlight, isHighlight }}>
      {children}
    </HighlightContext.Provider>
  );
};

export { useHighlightContext, HighlightProvider };
