import TimeLine from "./TimeLine";

function GraphContainer() {
  return (
    <div className={"shrink-0 w-full overflow-x-scroll relative "}>
      <TimeLine />
    </div>
  );
}

export default GraphContainer;
