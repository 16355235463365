import _ from "lodash";
import { dateToX } from "../hooks/useTimeLine";
import { Band, TimeTag } from "./TimeLine";

function SmallTimeLine({ timeFrames, maxWidth }) {
  const firstYear = timeFrames[0].started.getFullYear();
  const lastYear = timeFrames[timeFrames.length - 1].ended.getFullYear() + 1;

  const makeExtraSmall = lastYear - firstYear > 5;
  const yearWidth = makeExtraSmall ? 30 : 60;

  const yearList = _.range(firstYear, lastYear + 1, 1);
  const highLights = timeFrames.map((timeFrame) => [
    dateToX(
      yearList.length * yearWidth,
      new Date(firstYear, 0, 1),
      new Date(lastYear + 1, 0, 1),
      timeFrame.started
    ),
    dateToX(
      yearList.length * yearWidth,
      new Date(firstYear, 0, 1),
      new Date(lastYear + 1, 0, 1),
      timeFrame.ended
    ),
  ]);

  const timeLine = _.zipObject(
    yearList,
    _.range(0, yearList.length * yearWidth, yearWidth)
  );

  return (
    <div className={"flex flex-col flex-grow w-max h-5 pb-8 pt-2 mr-10"}>
      <div>
        <div
          className={"bg-gray-500 h-2 relative z-40"}
          style={{
            width: timeLine[lastYear] + 2,
          }}
        >
          {highLights.map((positionFrame, index) => (
            <div
              key={index}
              className={"absolute top-0 bottom-0 bg-red-400 text-xs"}
              style={{
                left: positionFrame[0],
                width: _.max([
                  positionFrame[1] - positionFrame[0],
                  yearWidth / 10,
                ]),
              }}
            />
          ))}
        </div>
      </div>
      <Band
        items={Object.keys(timeLine).map((year, index) => (
          <TimeTag
            key={year}
            time={year}
            hideText={
              makeExtraSmall &&
              index !== 0 &&
              index !== Object.keys(timeLine).length - 1
            }
          />
        ))}
        margin={Object.values(timeLine)}
      />
    </div>
  );
}

export default SmallTimeLine;
