function ClientList({ item }) {
  return item.clients.length > 0 ? (
    <div
      className={
        "flex flex-row text-sm text-gray-500 italic pt-2 flex-shrink-0 flex-wrap"
      }
    >
      {item.clients.map((client, index) => (
        <img
          key={index}
          src={client.logo}
          alt={client.title}
          onClick={() => window.open(client.ref, "_blank").focus()}
          className={"cursor-pointer w-16 pr-3 object-center object-contain"}
        />
      ))}
    </div>
  ) : (
    <div />
  );
}

export default ClientList;
