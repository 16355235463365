import { createContext, useContext, useEffect, useState } from "react";
import { notEmpty, reformatDate } from "../utils/time";

// const exp = {
//   personalDetails: {
//     firstName: "Daniël",
//     lastName: "Swaab",
//     adres: {
//       street: "Baltahasar van der Polweg",
//       number: 346,
//       postalCode: "2628AZ",
//       city: "Delft",
//       country: "The Netherlands",
//     },
//     about:
//       "As a freelancer with experience in full-stack development, game design, and data-science, I am constantly looking for new challenges and opportunities to grow. I am a versatile and quick learner, and pride myself on my ability to communicate clearly and think outside the box. In my free time, I am remodeling a van to create a home on wheels, allowing me to explore Europe and live a more flexible and free lifestyle.",
//   },
//   lookingFor: {
//     jobType: ["partTime, freelance, fulltime, contract, etc"],
//   },
//   experience: [
//     {
//       name: "Computer Science & Engineering",
//       id: 1,
//       inPortfolioList: false,
//       category: "education",
//       subName: "Bachelor Of Science",
//       organisation: "TU Delft",
//       style: {
//         backgroundColor: "yellow",
//         highlight: false,
//       },
//       keyWords: ["study"],
//       description:
//         " Combination of courses from logics, maths and programming. Learning all kinds of things, finished with a 7.4",
//       role: "student",
//       timeFrames: [
//         {
//           started: "01-09-2012",
//           ended: "31-08-2017",
//         },
//       ],
//       clients: [],
//       refs: "",
//       skills: ["prolog", "java", "latex"],
//       tools: ["Overleaf", "MaC"],
//       type: "",
//       media: "",
//       website: "",
//       isProject: false,
//       parent: "",
//     },
//     {
//       name: "Data Science Track",
//       id: 2,
//       inPortfolioList: false,
//       category: "education",
//       subName: "Specialization",
//       organisation: "TU Delft",
//       style: {
//         highlight: false,
//       },
//       keyWords: [""],
//       description:
//         "Learning about data related algorithms en programs, like: evolutionary algorithms, hadoop, spark, pig",
//       role: "student",
//       timeFrames: [
//         {
//           started: "01-09-2013",
//           ended: "01-12-2014",
//         },
//       ],
//       clients: [],
//       refs: "",
//       skills: ["hadoop", "spark", "pig"],
//       tools: ["Overleaf", "MaC"],
//       type: "",
//       media: {},
//       website: "",
//       isProject: false,
//       parent: 1,
//     },
//     {
//       name: "Bachelor Thesis",
//       id: 3,
//       inPortfolioList: false,
//       category: "education",
//       subName: "Internship",
//       organisation: "BUNQ",
//       style: {
//         highlight: false,
//       },
//       summary:
//         "Worked together in a team of 4 to develop a transaction monitoring system for my Bachelor Thesis.",
//       keyWords: [""],
//       description:
//         "Implementing a system that checks each transaction for fraud",
//       role: "intern",
//       timeFrames: [
//         {
//           started: "01-04-2017",
//           ended: "31-07-2017",
//         },
//       ],
//       clients: [],
//       refs: "",
//       skills: ["sql"],
//       tools: ["Grafana"],
//       type: "",
//       media: {},
//       website: "",
//       isProject: false,
//       parent: 1,
//     },
//     {
//       name: "Chinese, IT & Business",
//       id: 4,
//       inPortfolioList: false,
//       category: "education",
//       subName: "Minor",
//       organisation: "Tsinghua University, Beijing",
//       style: {
//         highlight: false,
//       },
//       summary:
//         "Studied for 6 months at Beijing University for my minor as part of my bachelor",
//       keyWords: [""],
//       description:
//         "Studied for 6 months at Beijing University for my minor as part of my bachelor",
//       role: "student",
//       timeFrames: [
//         {
//           started: "01-08-2016",
//           ended: "31-01-2017",
//         },
//       ],
//       clients: [],
//       refs: "",
//       skills: ["go", "chinese"],
//       tools: ["Grafana"],
//       type: "",
//       media: {},
//       website: "",
//       isProject: false,
//       parent: 1,
//     },
//     {
//       name: "Data science & entrepreneurship",
//       id: 5,
//       inPortfolioList: false,
//       category: "education",
//       subName: "Master",
//       organisation: "TU Delft",
//       style: {
//         highlight: false,
//       },
//       keyWords: [""],
//       description: "finished with a 8",
//       role: "student",
//       timeFrames: [
//         {
//           started: "01-09-2017",
//           ended: "31-02-2020",
//         },
//       ],
//       clients: [],
//       refs: "",
//       skills: ["matlab", "python"],
//       tools: ["overleaf"],
//       type: "",
//       media: {},
//       website: "",
//       isProject: false,
//       parent: "",
//     },
//     {
//       name: "DenkendeDoeners",
//       id: 33,
//       inPortfolioList: false,
//       category: "Initiatives",
//       subTitle: "Think, make, repeat",
//       subName: "Denken en doen",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//       summary:
//         "Wij geloven in een leerproces waarin doen en denken hand in hand gaan. Door de oplossingen die je genereert snel te testen zorg je ervoor dat je snel kan bijsturen. Teamwerk, reflecties en doelgericht werken komen daardoor het beste tot hun recht.",
//       keyWords: [
//         "workshops",
//         "game development",
//         "play",
//         "escape games",
//         "serious games",
//       ],
//       role: "Co-Founder",
//       timeFrames: [
//         {
//           started: "01-02-2020",
//           ended: null,
//         },
//       ],
//       clients: ["Humanoids", "TU-Delft"],
//       refs: "",
//       skills: [""],
//       tools: [""],
//       type: "",
//       media: {
//         attachments: {},
//         logo: "",
//         video: "https://www.youtube.com/watch?v=w2dOXOapad0",
//       },
//       learningPoints: {},
//       website: "denkendedoeners.nl",
//       projectLink: "",
//       isProject: false,
//       parent: "",
//     },
//     {
//       name: "HydroHero: Serious game",
//       id: 6,
//       inPortfolioList: true,
//       category: "education",
//       subName:
//         "Paper: A Serious Game to Inform Young Citizens on Canal Water Maintenance ",
//       organisation: "TU Delft",
//       style: {
//         highlight: false,
//       },
//       keyWords: ["serious game", "publication", "canal water maintenance"],
//       description:
//         "During the course 'Behavior Change Support Systems' I was part of a multidisciplinary team. Our goal was to develop a serious game for a real client. We created HydroHero to help children learn about canal maintenance. We published a paper at the Games and Learning Alliance conference. I went to the conference to present our findings.",
//       role: "student",
//       timeFrames: [
//         {
//           started: "01-01-2018",
//           ended: "01-04-2018",
//         },
//       ],
//       clients: [
//         {
//           name: "Science Centre Delft",
//           logo: "media/hydrohero/logo-science-center-delft.jpg",
//           ref: "https://www.tudelft.nl/sciencecentre",
//         },
//         {
//           name: "TU-Delft",
//           logo: "media/tudelft_logo.jpeg",
//           ref: "https://www.tudelft.nl/",
//         },
//       ],
//       refs: "",
//       skills: ["js", "html", "css"],
//       tools: ["overleaf"],
//       type: "",
//       media: {
//         attachments: {
//           paper: "media/hydrohero/attachments/Paper_Hydro_Hero.pdf",
//           play: "https://dswaab.nl/hydrohero/",
//         },
//         side2: "media/hydrohero/hydrohero_full.mov",
//         side: "media/hydrohero/drive-download-20221225T163415Z-001/Screenshot 2019-01-21 at 19.01.43.png",
//       },
//       website: "https://dswaab.nl/hydrohero/",
//       isProject: true,
//       parent: 5,
//     },
//     {
//       name: "Joymp: Game for Master thesis",
//       id: 7,
//       inPortfolioList: true,
//       subName:
//         "The relation between video game addiction and video game play motivation",
//       category: "education",
//       organisation: "TU Delft",
//       style: {
//         highlight: false,
//       },
//       keyWords: ["game addiction", "game motivation"],
//       description:
//         "During my thesis I developed a survey based on a literature study of game motivators and game addication. Based on this survey I found two potential factors that incentivize people to play more. I developed a infinite runner to test these principles, based on an A version (including the factors) and a B version (exluding the factors). Due to inconclusive data, no statistically relevant factors could be found.",
//       role: "student",
//       timeFrames: [
//         {
//           started: "27-02-2019",
//           ended: "31-02-2020",
//         },
//       ],
//       clients: [
//         {
//           name: "TU-Delft",
//           logo: "media/tudelft_logo.jpeg",
//           ref: "https://www.tudelft.nl/",
//         },
//       ],
//       refs: "",
//       skills: ["c#", "js", "python"],
//       tools: ["django", "react", "unity"],
//       type: "",
//       media: {
//         attachments: {
//           thesis: "media/thesis/thesis_dswaab.pdf",
//           "play-store":
//             "https://play.google.com/store/apps/details?id=com.Swaabit.Joymp&hl=nl&gl=US",
//         },
//         side: "media/thesis/joymp.png",
//       },
//       website:
//         "https://play.google.com/store/apps/details?id=com.Swaabit.Joymp&hl=nl&gl=US",
//       isProject: true,
//       parent: 5,
//     },
//     {
//       name: "PublicWorkspaces",
//       id: 9,
//       inPortfolioList: true,
//       category: "Home projects",
//       subName:
//         "Platform for students to find their favorite place to study in Delft",
//       description:
//         "A friend and I challenged ourselves to build a startup in 24 hours. In order to create an overview of all public workspaces in Delft, we interviewed the staff on site.  We built a website that showed availability and amenities.",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//       keyWords: ["24 hour startup", "hackathon"],
//       role: "co-creator",
//       timeFrames: [
//         {
//           started: "27-03-2019",
//           ended: "28-03-2019",
//         },
//       ],
//       clients: [],
//       refs: "",
//       skills: ["js"],
//       tools: ["firebase"],
//       type: "",
//       media: {
//         attachments: {
//           blog: "https://www.wouterraateland.nl/blog/building-a-startup-in-24-hours",
//         },
//         side: "media/images/publicworkspaces.webp",
//       },
//       website: "",
//       isProject: true,
//       parent: "",
//     },
//     {
//       name: "DenkendeDoeners: Escape game workshops",
//       id: 34,
//       inPortfolioList: true,
//       category: "Work",
//       subName: "Facilitating experiences focused on development",
//       organisation: "denkendedoeners",
//       style: {
//         highlight: false,
//       },
//       description:
//         "I co-founded DenkendeDoeners during COVID. We developed and taught workshops about escape game design, initially digital, now analog as well. The workshops combines learning with a lot of interaction, puzzles, and rewards. The morning consists of five high paced presentations, followed by group-exercises. In the afternoon each team designs and tests their game.",
//       keyWords: ["education", "workshop", "game design", "escape room"],
//       role: "Co-Founder",
//       timeFrames: [
//         {
//           started: "12-03-2020",
//           ended: "13-03-2020",
//         },
//         {
//           started: "12-03-2021",
//           ended: "13-03-2021",
//         },
//         {
//           started: "12-03-2022",
//           ended: "13-03-2022",
//         },
//         {
//           started: "24-07-2022",
//           ended: "25-07-2022",
//         },
//         {
//           started: "17-12-2022",
//           ended: "18-12-2022",
//         },
//       ],
//       clients: [
//         {
//           name: "Humanoids",
//           logo: "media/denkendedoeners/humaniods logo.png",
//           ref: "https://humanoids.nl/",
//         },
//         {
//           name: "TU-Delft",
//           logo: "media/tudelft_logo.jpeg",
//           ref: "https://www.tudelft.nl/",
//         },
//         {
//           name: "denkendedoeners",
//           logo: "media/denkendedoeners/logo-final.png",
//           ref: "https://www.denkendedoeners.nl/",
//         },
//       ],
//       refs: "",
//       skills: ['"trash"'],
//       tools: ["adobe"],
//       type: "",
//       media: {
//         attachments: {
//           website: "https://denkendedoeners.nl/#/workshops",
//         },
//         projectBanner: "",
//         images: [],
//         logo: "",
//         video: "https://denkendedoeners.nl/images/workshop/workshop-gif.gif",
//         side: "https://denkendedoeners.nl/images/workshop/workshop-gif.gif",
//       },
//       learningPoints: {},
//       website: "https://denkendedoeners.nl/#/workshops",
//       projectLink: "",
//       isProject: true,
//       parent: "",
//     },
//     {
//       name: "Amnesia: Game",
//       id: 10,
//       subName: "Get bugged in a 10 year old game",
//       keyWords: ["adventure", "high-school"],
//       description:
//         "As final assignment for Informatics, a friend and I explored unity. We designed a small adventure game, it is the first game that I made.",
//       timeFrames: [
//         {
//           started: "31-03-2012",
//           ended: "31-04-2012",
//         },
//       ],
//       clients: [],
//       skills: ["c#"],
//       tools: ["unity"],
//       media: {
//         attachments: {
//           "game.exe":
//             "https://drive.google.com/file/d/1ikmBGu4M6PHBWytw2XYUj7_yJlopx2Mt/view?usp=sharing",
//         },
//         side: "media/amnesia/amnesia.png",
//       },
//       website: "",
//       inPortfolioList: true,
//       isProject: true,
//       type: "",
//       refs: "",
//       parent: null,
//       role: "",
//       category: "",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//     },
//     {
//       name: "ScoolX: Serious game",
//       id: 11,
//       subName: "Go beyond learning",
//       keyWords: ["language learning", "children"],
//       description:
//         "European Innovation Academy (EIA) is a 3 week program to learn the ins and outs of building a startup. Together with my team I created Scoolx. We used augmented reality to make an interactive learning experience for young children. By directing the camera to a theme card, a world within that theme would appear. Objects in the world could be clicked to hear to correct pronunciation in the preferred language.",
//       timeFrames: [
//         {
//           started: "01-08-2017",
//           ended: "31-08-2017",
//         },
//       ],
//       clients: [
//         {
//           name: "Europian Innovation Accadamy",
//           logo: "media/scoolx/EIA-LOGO.svg",
//           ref: "https://www.inacademy.eu/",
//         },
//         {
//           name: "ScoolX",
//           logo: "media/scoolx/scoolx_logo.png",
//           ref: "media/scoolx/scoolx_com.pdf",
//         },
//       ],
//       skills: ["c#"],
//       tools: ["unity", "vuforia"],
//       media: {
//         attachments: {
//           apk: "media/scoolx/scoolx.apk",
//         },
//         side: "media/scoolx/scoolx_banner.png",
//       },
//       website: "",
//       inPortfolioList: true,
//       isProject: true,
//       type: "",
//       refs: "",
//       parent: null,
//       role: "",
//       category: "",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//     },
//     {
//       name: "TreeChange: Behavioral change app",
//       id: 12,
//       subName: "Change three habits in five weeks",
//       keyWords: ["behavior change", "mental health"],
//       description:
//         "During my thesis I did a lot of research into motivation and addiction. I came up with a methodology to help change behavior, and created TreeChange to make it accessible to the public. The app applies streak motivation and incentivizes self-efficacy. You choose a small, medium and big habit you would like to change. Starting with the small one, each change will take 3 weeks. Every week the next level of difficulty is added.",
//       timeFrames: [
//         {
//           started: "01-02-2020",
//           ended: "01-07-2020",
//         },
//       ],
//       clients: [
//         {
//           name: "TreeChange",
//           logo: "media/treechange/logo.webp",
//           ref: "https://play.google.com/store/apps/details?id=com.swaabit.treechange&hl=nl",
//         },
//       ],
//       skills: ["js", "html", "css", "react-native"],
//       tools: ["tailwindcss", "expo", "adobe"],
//       media: {
//         attachments: {
//           "play-store":
//             "https://play.google.com/store/apps/details?id=com.swaabit.treechange&hl=nl",
//         },
//         side: "media/treechange/treechange_app_3.png",
//       },
//       website: "",
//       inPortfolioList: true,
//       isProject: true,
//       type: "",
//       refs: "",
//       parent: null,
//       role: "",
//       category: "",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//     },
//     {
//       name: "MTG card reader: App",
//       id: 13,
//       subName: "Scan more than 50 cards at the same time",
//       keyWords: ["Google cloud API", "Magic the gathering"],
//       description:
//         "App that uses Google’s ‘image to text’ API to extract text from a photo of multiple 'Magic The Gathering' cards. An algorithm compares the text to known card names, highlights the found cards, and produces a list of the names of the cards on the photo.",
//       timeFrames: [
//         {
//           started: "17-12-2017",
//           ended: "14-03-2018",
//         },
//       ],
//       clients: [],
//       skills: ["c#", "js"],
//       tools: ["unity", "google cloud"],
//       media: {
//         attachments: {},
//         side: "media/mtg card reader/side.png",
//       },
//       website: "",
//       inPortfolioList: true,
//       isProject: true,
//       type: "",
//       refs: "",
//       parent: null,
//       role: "",
//       category: "",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//     },
//     {
//       name: "About sex: Serious escape game",
//       id: 14,
//       subName: "About safety, fun and consent",
//       keyWords: ["serious escape game", "sexual education"],
//       description:
//         "I developed a serious escape game for the GGD. The goal was to open up a conversation about three important aspects of sex: safety, fun and consent. The game was designed to be played by a group up to 3 children, for about 20 minutes, and was mobile. Commissioned by Raccoon Serious Games.",
//       timeFrames: [
//         {
//           started: "14-07-2018",
//           ended: "29-01-2019",
//         },
//       ],
//       clients: [
//         {
//           name: "GGD Hollands Midden",
//           logo: "media/escape/ggd_logo.jpeg",
//           ref: "https://www.ggdhm.nl/",
//         },
//         {
//           name: "Raccoon Serious Games",
//           logo: "media/escape/raccoon_logo.png",
//           ref: "https://raccoon.games/",
//         },
//       ],
//       skills: [],
//       tools: ["adobe"],
//       media: {
//         attachments: {
//           "product link": "https://raccoon.games/case-sense/",
//         },
//         side: "media/escape/ggd_banner.webp",
//       },
//       website: "",
//       inPortfolioList: true,
//       isProject: true,
//       type: "",
//       refs: "",
//       parent: null,
//       role: "",
//       category: "",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//     },
//     {
//       name: "Rising tides: Serious game",
//       id: 15,
//       subName: "Creating awareness for climate change and sustainability",
//       keyWords: ["serious game", "water management", "climate change"],
//       description:
//         "A game about climate change and the consequences for the Netherlands in regards to rising sea levels. In the first part of the game, small teams try to find sustainable solutions for current challenges, to avoid climate degradation. By the second part it becomes clear that small measures aren't going to cut it, so multiple teams have to collaborate to fight against the rising tides. I was involved in project management, game development and running multiple events.",
//       timeFrames: [
//         {
//           started: "22-06-2018",
//           ended: "31-10-2018",
//         },
//         {
//           started: "30-06-2019",
//           ended: "3-07-2019",
//         },
//       ],
//       clients: [
//         {
//           name: "Raccoon Serious Games",
//           logo: "media/escape/raccoon_logo.png",
//           ref: "https://raccoon.games/",
//         },
//       ],
//       skills: ["adobe"],
//       tools: ["trello"],
//       media: {
//         attachments: {
//           "product link":
//             "https://raccoon.games/case-serious-escape-game-klimaatverandering/",
//         },
//         side: "media/escape/fight_against_the_water.webp",
//       },
//       website: "",
//       inPortfolioList: true,
//       isProject: true,
//       type: "",
//       refs: "",
//       parent: null,
//       role: "",
//       category: "",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//     },
//     {
//       name: "Escape game development",
//       id: 16,
//       subName: "",
//       keyWords: ["escape game", "puzzle design"],
//       description:
//         "During my time as an escape room developer for 'Popup-Escape' and corporate-escape I worked on a multitude of projects. Games I worked on included: A game used in job interviews (1 p), migration game (5 p), Torenhoven (5-20 p). My personal favorite was 'Not your vault', where I was project lead and designed the game. It was located in and old basement of ABN containing a real vault. The game was collaborative and playable up to 20 people. I am especially proud of the level of immersion and story that was woven through the puzzles.",
//       timeFrames: [
//         {
//           started: "01-07-2017",
//           ended: "01-02-2020",
//         },
//       ],
//       clients: [
//         {
//           name: "Pupup-Escape",
//           logo: "media/escape/popup-escape.png",
//           ref: "http://popup-escape.nl/",
//         },
//       ],
//       skills: ["adobe"],
//       tools: ["trello"],
//       media: {
//         attachments: {},
//         side: "media/escape/escape_game_banner_sized.png",
//       },
//       website: "",
//       inPortfolioList: true,
//       isProject: true,
//       type: "",
//       refs: "",
//       parent: null,
//       role: "",
//       category: "",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//     },
//     {
//       name: "Website development",
//       id: 17,
//       subName: "",
//       keyWords: ["web development", "frontend"],
//       description:
//         "I have created many websites over the years, most of them with react and wordpress. I got my fair share of frontend development and web languages.",
//       timeFrames: [
//         {
//           started: "11-11-2014",
//           ended: "05-02-2014",
//         },
//         {
//           started: "03-02-2016",
//           ended: "01-03-2016",
//         },
//         {
//           started: "24-06-2016",
//           ended: "31-06-2016",
//         },
//         {
//           started: "03-07-2017",
//           ended: "10-09-2017",
//         },
//         {
//           started: "03-02-2020",
//           ended: "01-05-2020",
//         },
//         {
//           started: "06-06-2022",
//           ended: "12-07-2022",
//         },
//       ],
//       clients: [],
//       skills: ["js", "css", "html", "php"],
//       tools: ["react", "wordpress", "XD", "illustrator", "photoshop"],
//       media: {
//         attachments: {
//           martijnswaab: "https://martijnswaab.nl/",
//           swaabenschild: "https://www.swaabenschild.nl/",
//           denkendedoeners: "https://denkendedoeners.nl/",
//           vakwerkdeventer: "https://vakwerkdeventer.nl/",
//           mirjamschild: "https://mirjamschild.nl/",
//           dswaab: "https://dswaab.nl/",
//           swaabit: "https://swaabit.nl/",
//           groenevingers: "https://www.groenevingersdelft.nl/",
//         },
//         side: "media/website development/banner.webp",
//       },
//       website: "",
//       inPortfolioList: true,
//       isProject: true,
//       type: "",
//       refs: "",
//       parent: null,
//       role: "",
//       category: "",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//     },
//     {
//       name: "Idea Comparison tool",
//       id: 18,
//       subName: "More then 100 ideas present",
//       keyWords: ["data science", "data analysis", "visualization"],
//       description:
//         "Used Coda to develop a database for logging ideas, and scoring them based on a set of chosen variables. Each variable is assigned a weight, which enables the user to order the ideas on relative importance. This gives the user an overview of the ideas they enjoy working on the most.",
//       timeFrames: [
//         {
//           started: "20-03-2019",
//           ended: "31-03-2019",
//         },
//       ],
//       clients: [],
//       skills: [],
//       tools: ["coda"],
//       media: {
//         attachments: {},
//         side: "media/idecomparison/idea_comparison.png",
//       },
//       website: "",
//       inPortfolioList: true,
//       isProject: true,
//       type: "",
//       refs: "",
//       parent: null,
//       role: "",
//       category: "",
//       organisation: "",
//       style: {
//         highlight: false,
//       },
//     },
//   ],
// };

function formatDate(data) {
  data.experience.forEach((detail) => {
    detail.timeFrames.forEach((obj) => {
      obj.started = reformatDate(obj.started);
      obj.ended = obj.ended ? reformatDate(obj.ended) : new Date();
    });
  });
  return data;
}

const DataContext = createContext({
  data: {},
  projects: [],
});

// context consumer hook
const useDataContext = () => {
  // get the context
  return useContext(DataContext);
};

const DataProvider = ({ children }) => {
  // const data = formatDate(exp);
  const [data, setData] = useState();
  const [projects, setProjects] = useState();
  // // Get data from the json file
  useEffect(() => {
    fetch("./experience.json")
      .then((response) => response.json())
      .then((json) => formatDate(json))
      .then((json) => setData(json));
  }, []);

  //create a list of the projects to show in a detailfield
  useEffect(() => {
    if (notEmpty(data)) {
      setProjects(data.experience.filter((details) => details.isProject));
    }
  }, [data]);

  return (
    <DataContext.Provider value={{ data, projects }}>
      {children}
    </DataContext.Provider>
  );
};

export { useDataContext, DataProvider };
