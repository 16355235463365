import { createContext, useContext, useEffect, useState } from "react";
import { useDataContext } from "./DataContext";
import { notEmpty } from "../utils/time";
import { useHighlightContext } from "./HighlightContext";

const FilterContext = createContext({
  activeExperienceList: [],
  activeProjectList: [],
  filters: {
    subject: {},
    skills: {},
    tool: {},
    framework: {},
    role: {},
  },
  textSearch: "",
  setTextSearch: () => {},
  setFilters: () => {},
  selectFilter: () => {},
});

const useFilterContext = () => {
  return useContext(FilterContext);
};

const sortExperiencesOnDate = (experienceList) => {
  return experienceList.sort((objA, objB) => {
    return (
      Number(objA.timeFrames[0].started) - Number(objB.timeFrames[0].started)
    );
  });
};

const AllKeysToFilter = (key, obj) => {
  return [
    ...new Set(
      obj
        .map((item) => {
          if (key in item) {
            return item[key];
          }
          return [];
        })
        .flat()
    ),
  ]
    .filter((item) => item !== "")
    .map((s) => s.toLowerCase())
    .reduce((acc, key) => ({ ...acc, [key]: false }), {});
};

const DeepObjectValuesToText = (text, obj) => {
  if (obj !== null && obj !== undefined) {
    let newText = "";
    Object.values(obj).forEach((value) => {
      if (value !== null && value !== undefined) {
        if (["string", "number", "boolean"].includes(typeof value)) {
          newText += " " + value;
        } else if (["object", "Object"].includes(typeof value)) {
          newText += DeepObjectValuesToText(text, value);
        }
      }
    });
    text += newText;
  }
  return text;
};

const FilterContextProvider = ({ children }) => {
  const { data } = useDataContext();
  const [activeExperienceList, setActiveExperienceList] = useState([]);
  const [activeProjectList, setActiveProjectList] = useState([]);
  const [filters, setFilters] = useState({});
  const [textSearch, setTextSearch] = useState("");
  const { highlight, setHighlight } = useHighlightContext();

  const selectFilter = (key, filterKey) => {
    setFilters({
      ...filters,
      [filterKey]: {
        ...filters[filterKey],
        [key]: !filters[filterKey][key],
      },
    });
  };

  useEffect(() => {
    if (data?.experience) {
      setActiveExperienceList(
        sortExperiencesOnDate(
          data.experience
            .filter((details) =>
              Object.keys(filters)
                .map((filterKey) => {
                  if (filterKey in details) {
                    if (typeof details[filterKey] == "string") {
                      const word = details[filterKey];
                      return (
                        filters[filterKey][word] ||
                        !Object.values(filters[filterKey]).includes(true)
                      );
                    } else
                      return details[filterKey].length > 0
                        ? details[filterKey]
                            .map(
                              (word) =>
                                filters[filterKey][word] ||
                                !Object.values(filters[filterKey]).includes(
                                  true
                                )
                            )
                            .includes(true)
                        : true;
                  }
                })
                .every((item) => item)
            )
            .filter((details) => {
              const detailsText = DeepObjectValuesToText(
                "",
                details
              ).toLowerCase();
              return (
                textSearch === "" ||
                textSearch
                  .toLowerCase()
                  .split(" ")
                  .map((word) => detailsText.includes(word))
                  .every((item) => item)
              );
            })
        )
      );
    }
  }, [filters, data, textSearch]);

  // reset the highlight if it's not in the filters anymore
  useEffect(() => {
    if (
      highlight &&
      [
        ...activeProjectList.map((item) => item.id === highlight.id),
        ...activeExperienceList.map((item) => item.id === highlight.id),
      ].includes(true)
    ) {
      // do nothing
    } else {
      setHighlight(undefined);
    }
  }, [activeProjectList, activeExperienceList, highlight, setHighlight]);

  useEffect(() => {
    // create the filters
    if (data?.experience) {
      setFilters({
        keyWords: AllKeysToFilter("keyWords", data?.experience),
        skills: AllKeysToFilter("skills", data?.experience),
        tools: AllKeysToFilter("tools", data?.experience),
        themes: AllKeysToFilter("themes", data?.experience),
        platforms: AllKeysToFilter("platforms", data?.experience),
        role: AllKeysToFilter("role", data?.experience),
        category: AllKeysToFilter("category", data?.experience),
        // language: data?.experience?.skills?.map((item) => ({ item: false })),
        // framework: data?.experience?.keywords?.map((item) => ({ item: false })),
      });
    }
  }, [data]);

  //create a list of the projects to show in a detailfield
  useEffect(() => {
    if (notEmpty(activeExperienceList)) {
      setActiveProjectList(
        activeExperienceList.filter((details) => details.isProject)
      );
    }
  }, [activeExperienceList]);

  return (
    <FilterContext.Provider
      value={{
        activeExperienceList,
        activeProjectList,
        filters,
        setFilters,
        selectFilter,
        textSearch,
        setTextSearch,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export { useFilterContext, FilterContextProvider };
