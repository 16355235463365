import DashedLine from "./DashedLine";
import { useTimeLine } from "../hooks/useTimeLine";
import { useHighlightContext } from "../context/HighlightContext";

function TimeLineSinglePoint({
  icon,
  details,
  height = 125,
  heightAdjustment = 0,
}) {
  const { timeFrameToTimeLine } = useTimeLine();
  const { isHighlight, setHighlight } = useHighlightContext();
  const [startPos] = timeFrameToTimeLine(details?.timeFrames[0]);

  // Using min margin  in combination with increased height to let the dash go to the timeline
  return (
    <div
      className={"flex flex-row absolute bottom-0 -mb-2 items-start"}
      style={{ left: startPos ? startPos : 0 }}
    >
      <div
        style={{ height: height + heightAdjustment }}
        className={"flex flex-col items-center h-32 overflow-hidden"}
      >
        {icon}
        <DashedLine
          dashLength={4}
          dashThickness={1.5}
          dashGap={4}
          dashColor={isHighlight(details.id) ? "bg-swaabit-red" : "bg-gray-400"}
          repetitions={30}
          vertical={true}
          key={"dash"}
        />
      </div>
      <div
        className={"-mt-1 pl-1 text-sm z-10 cursor-pointer"}
        onClick={() => setHighlight(details)}
      >
        {details.title}
      </div>
    </div>
  );
}

export default TimeLineSinglePoint;
