import FilterSection from "./FilterSection";
import { useEffect, useState } from "react";
import PortfolioListPage from "../pages/PortfolioListPage";
import { useDataContext } from "../context/DataContext";
import Button from "./Button";
import classNames from "classnames";

function SideBarMobile() {
  const [showInfo, setShowInfo] = useState(false);
  const { data } = useDataContext();
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [experienceItems, setExperienceItems] = useState([]);

  useEffect(() => {
    // reversed is used for startiong with the most recent items
    if (data) {
      setPortfolioItems(
        data.experience
          .filter((details) => details.isProject)
          .filter((item) => item.inPortfolioList)
          .sort((objA, objB) => {
            return (
              Number(objA.timeFrames[0].started) -
              Number(objB.timeFrames[0].started)
            );
          })
          .reverse()
      );
      setExperienceItems(
        data.experience
          .filter((details) => !details.isProject)
          .sort((objA, objB) => {
            return (
              Number(objA.timeFrames[0].started) -
              Number(objB.timeFrames[0].started)
            );
          })
          .reverse()
      );
    }
  }, [data]);

  return (
    <>
      <div className={"h-max w-full bg-swaabit-sun flex flex-col items-center"}>
        <div
          className={classNames(
            "text-red-200 bg-red-500 z-50 px-2 py-1"
            // " rounded mx-8 mt-2 -mb-10"
          )}
        >
          Open for projects! Preferably in frontend development or serious game
          design
        </div>
        <div
          style={{ width: 220, height: 220 }}
          className={"bg-red-300 rounded-full overflow-hidden  mt-6 shrink-0"}
        >
          <img
            src={"media/profile picture/profile.jpeg"}
            alt={"daniel_swaab_profile"}
          />
        </div>
        <div className={"mt-3 mb-6"}>
          <Button
            className={
              "text-swaabit-sea shadow-none hover:bg-swaabit-blue hover:bg-opacity-10 mr-2"
            }
            onClick={() => window.open("tel:+31641152536")}
          >
            CALL
          </Button>
          <Button
            className={
              "text-swaabit-sea shadow-none hover:bg-swaabit-blue hover:bg-opacity-10 ml-2"
            }
            onClick={() => window.open("mailto:swaab.daniel@gmail.com")}
          >
            MAIL
          </Button>
        </div>
        <div className={"w-full px-6"}>
          <div
            className={"border-b border-swaabit-sun2 w-full "}
            style={{ borderWidth: 1 }}
          />
        </div>

        <div className={"h-full  px-6 mt-4"}>
          <div className={"flex flex-row justify-between"}>
            <div className={"text-4xl font-thin"}>Daniël Swaab</div>
            {/*<Info*/}
            {/*  fontSize={"small"}*/}
            {/*  onClick={() => setShowInfo(!showInfo)}*/}
            {/*  className={"cursor-pointer"}*/}
            {/*/>*/}
          </div>

          <div className={"text-sm text-left mb-6 relative"}>
            {showInfo ? (
              <>
                <div className={"font-thin -mt-2 text-base mb-2"}>
                  {data.personalDetails.title.toUpperCase()}
                </div>
                <div>Born 15 august 1994</div>
                <div>Lives in Delft</div>
                <div className={"text-base font-light text-left mt-2"}>
                  {data.personalDetails.about}
                </div>
              </>
            ) : (
              <div className={"font-light text-base text-gray-800 "}>
                Welcome! This page shows the experiences I've gathered through
                life. Have a look around and feel free to contact me. For a
                complete timeline and overview make sure to visit the website
                via a PC.
              </div>
            )}

            <Button
              onClick={() => setShowInfo(!showInfo)}
              className={
                "absolute -bottom-5 right-0 text-swaabit-sea shadow-none hover:bg-swaabit-blue hover:bg-opacity-10"
              }
            >
              {showInfo ? "< less" : "more >"}
            </Button>
          </div>

          <FilterSection />
        </div>
      </div>

      <div className={"bg-white mt-10 mb-10"}>
        <PortfolioListPage
          list={portfolioItems}
          text={
            "For the past two years I’ve been working as freelancer on mostly full-stack projects, related to data science. This portfolio shows the other projects I've worked on in the past. It's a combination of work and personal projects."
          }
          title={"Portfolio"}
        />
        <PortfolioListPage
          list={experienceItems}
          text={"Text about experiences"}
          title={"Experiences"}
        />
      </div>
    </>
  );
}

export default SideBarMobile;
