import FilterSection from "./FilterSection";
import { useState } from "react";
import PortfolioListPage from "../pages/PortfolioListPage";
import { useDataContext } from "../context/DataContext";
import Button from "./Button";

function SideBar({ showPortfolioList, setShowPortfolioList }) {
  const [showInfo, setShowInfo] = useState(false);
  const { data } = useDataContext();

  return (
    <>
      <div
        className={"h-screen w-60 bg-swaabit-sun relative z-50 flex flex-col"}
      >
        <div
          className={
            "absolute top-4 left-52 bg-red-200 w-max rounded text-swaabit-red z-50 px-2 py-1 text-left"
          }
        >
          Open for work! Preferably in frontend development or serious game
          design
        </div>
        <div
          style={{ width: 180, height: 180 }}
          className={
            "bg-red-300 rounded-full overflow-hidden mx-5 mt-4 shrink-0"
          }
        >
          <img
            src={"media/profile picture/profile.jpeg"}
            alt={"daniel_swaab_profile"}
          />
        </div>
        <div className={"mt-3 mb-3"}>
          <Button
            className={
              "text-swaabit-sea shadow-none hover:bg-swaabit-blue hover:bg-opacity-10 mr-2"
            }
            onClick={() => window.open("tel:+31641152536")}
          >
            CALL
          </Button>
          <Button
            className={
              "text-swaabit-sea shadow-none hover:bg-swaabit-blue hover:bg-opacity-10 ml-2"
            }
            onClick={() => window.open("mailto:swaab.daniel@gmail.com")}
          >
            MAIL
          </Button>
        </div>
        <div className={"mx-4 h-full overflow-y-hidden"}>
          <div className={"flex flex-row justify-between"}>
            <div className={"text-lg"}>Daniël Swaab</div>
            {/*<Info*/}
            {/*  fontSize={"small"}*/}
            {/*  onClick={() => setShowInfo(!showInfo)}*/}
            {/*  className={"cursor-pointer"}*/}
            {/*/>*/}
          </div>

          <div className={"text-sm text-left mb-2 relative"}>
            {showInfo ? (
              <>
                <div className={"font-thin -mt-2 text-base mb-2"}>
                  {data.personalDetails.title.toUpperCase()}
                </div>
                <div>Born 15 august 1994</div>
                <div>Lives in Delft</div>
                <div className={"text-sm font-light text-left mt-2"}>
                  {data.personalDetails.about}
                </div>
              </>
            ) : (
              <div className={"font-light text-gray-800 "}>
                Welcome on my website! These are the experiences I've gathered
                through life. Have a look around and feel free to contact me.
              </div>
            )}

            <Button
              onClick={() => setShowInfo(!showInfo)}
              className={
                "absolute bottom-0 right-0 text-swaabit-sea shadow-none hover:bg-swaabit-blue hover:bg-opacity-10"
              }
            >
              {showInfo ? "< less" : "more >"}
            </Button>
          </div>

          <Button
            size={"small"}
            className={
              "text-swaabit-sea shadow-none hover:bg-swaabit-blue hover:bg-opacity-10 mt-4 mb-2"
            }
            onClick={() => setShowPortfolioList(!showPortfolioList)}
          >
            {showPortfolioList ? "hide" : "show"} portfolio summary
          </Button>
          <FilterSection />
        </div>
      </div>

      {showPortfolioList && (
        <div className={"h-screen bg-white z-40 "}>
          <PortfolioListPage
            list={
              data
                ? data.experience
                    .filter((details) => details.isProject)
                    .sort((objA, objB) => {
                      return (
                        Number(objA.timeFrames[0].started) -
                        Number(objB.timeFrames[0].started)
                      );
                    })
                    .reverse()
                : []
            }
            text={
              "For the past two years I’ve been working as freelancer on mostly full-stack projects, related to data science. This portfolio shows the other projects I've worked on in the past. It's a combination of work and personal projects."
            }
            title={"Portfolio"}
          />
        </div>
      )}
    </>
  );
}

export default SideBar;
