import classNames from "classnames";

function DashedLine({
  vertical,
  dashGap,
  dashLength,
  dashThickness,
  dashColor,
  dashStyle,
  style,
  repetitions,
  rotationDegrees = 0,
  transformOrigin = "center",
}) {
  const dash = (key) => {
    return (
      <div
        key={key}
        style={{
          ...dashStyle,
          width: vertical ? dashThickness : dashLength,
          height: vertical ? dashLength : dashThickness,
          marginBottom: vertical ? dashGap : 0,
          marginRight: vertical ? 0 : dashGap,
          marginLeft: 1,
          marginTop: 1,
          transform: "rotate(" + rotationDegrees + "deg)",
          transformOrigin: transformOrigin,
        }}
        className={dashColor}
      />
    );
  };

  const line = () => {
    let l = [];
    for (let step = 0; step < repetitions; step++) {
      l.push(dash(step));
    }
    return l;
  };

  return (
    <div
      style={style}
      className={classNames(" flex", vertical ? "flex-col" : "flex-row")}
    >
      {line()}
    </div>
  );
}

export default DashedLine;
