import StarIcon from "@mui/icons-material/Star";
import TimeLineSinglePoint from "./TimeLineSinglePoint";
import TimeLineTimBarContainer from "./TimeLineTimeBarContainer";
import { Favorite, School } from "@mui/icons-material";
import { useHighlightContext } from "../context/HighlightContext";
import React from "react";

function TimeBarContainer({ experiences }) {
  const categoryColors = [
    "-swaabit-yellow",
    "-swaabit-sky",
    "-swaabit-sun",
    "-swaabit-sea",
    "-swaabit-blue",
  ];
  const { isHighlight } = useHighlightContext();

  const categories = Array.from(
    new Set(
      experiences.map((item) => item.category).filter((item) => item !== "")
    )
  ).reverse(); // reverse so that education is lowest

  const timeBarExperiences = experiences.filter(
    (item) => !item.isSinglePoint || item.isParent
  );

  const singlePointExperiences = experiences.filter(
    (item) => item.isSinglePoint
  );

  return (
    <div className={"relative"}>
      {singlePointExperiences.map((item) => (
        <TimeLineSinglePoint
          details={item}
          key={item.id}
          heightAdjustment={
            item.category === "extra"
              ? 16
              : item.category === "education"
              ? -6
              : 38
          }
          icon={React.createElement(
            item.category === "extra"
              ? StarIcon
              : item.category === "education"
              ? School
              : Favorite,
            {
              sx: { fontSize: 16 },
              className: isHighlight(item.id)
                ? "text-swaabit-red"
                : `text${categoryColors[categories.indexOf(item.category, 0)]}`,
            }
          )}
        />
      ))}
      {categories.map((category, index) => (
        <TimeLineTimBarContainer
          key={index}
          category={category}
          color={categoryColors[index]}
          experiences={timeBarExperiences.filter(
            (item) => item.category === category
          )}
        />
      ))}
    </div>
  );
}

export default TimeBarContainer;
