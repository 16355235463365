import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DataProvider } from "./context/DataContext";
import { FilterContextProvider } from "./context/FilterContext";
import { TimeLineProvider } from "./context/TimeLineContext";
import { HighlightProvider } from "./context/HighlightContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <DataProvider>
      <HighlightProvider>
        <FilterContextProvider>
          <TimeLineProvider>
            <App />
          </TimeLineProvider>
        </FilterContextProvider>
      </HighlightProvider>
    </DataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
