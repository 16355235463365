import _ from "lodash";
import { notEmpty } from "../utils/time";
import * as PropTypes from "prop-types";
import { DetailView } from "./GraphDetailsSection";
import { useEffect, useState } from "react";
import {
  MIN_HIGHLIGHT_WIDTH,
  PROJECT_MARGIN,
  PROJECT_WIDTH,
  TOTAL_PROJECT_SIZE,
} from "../utils/constants";
import { useFilterContext } from "../context/FilterContext";
import { useTimeLineContext } from "../context/TimeLineContext";
import { useTimeLine } from "../hooks/useTimeLine";
import TimeBarContainer from "./TimeBarContainer";
import { useHighlightContext } from "../context/HighlightContext";

function TimeTag({ time, hideText }) {
  return (
    <div className={"border-l-2 border-gray-400 pl-1 text-xs align-bottom"}>
      {hideText ? "" : time}
    </div>
  );
}

function Band({ margin, items }) {
  return (
    <div className={"flex flex-row relative"}>
      {items?.map((item, index) => (
        <div
          className={"absolute text-xs"}
          style={{ left: margin[index] }}
          key={index}
        >
          {item}
        </div>
      ))}
    </div>
  );
}

DetailView.propTypes = {
  onClick: PropTypes.func,
  isUpper: PropTypes.any,
};

function TimeLine() {
  const [timeLineHighlight, setTimeLineHighlight] = useState([]);
  const [projectPositions, setProjectPositions] = useState([]);
  const [activeProjectList, setActiveProjectList] = useState([]);
  const { activeExperienceList } = useFilterContext();
  const { baseTimeLine, lastYear } = useTimeLineContext();
  const { highlight, setHighlight } = useHighlightContext();
  const { timeFrameToTimeLine } = useTimeLine();

  useEffect(() => {
    if (highlight) {
      setTimeLineHighlight(
        highlight.timeFrames.map((timeFrame) => timeFrameToTimeLine(timeFrame))
      );
    } else {
      setTimeLineHighlight([]);
    }
  }, [highlight, baseTimeLine]);

  useEffect(() => {
    setActiveProjectList(
      activeExperienceList?.filter((details) => details.isProject)
    );
  }, [activeExperienceList]);

  useEffect(() => {
    if (notEmpty(activeProjectList)) {
      setProjectPositions(
        activeProjectList.map((item) => {
          const sameYear = activeProjectList.filter(
            (project) =>
              project.timeFrames[0].started.getFullYear() ===
              item.timeFrames[0].started.getFullYear()
          );
          const projectCount = sameYear.indexOf(item);
          return (
            baseTimeLine[item.timeFrames[0].started.getFullYear()] +
            TOTAL_PROJECT_SIZE * projectCount
          );
        })
      );
    }
  }, [activeProjectList, baseTimeLine]);

  return (
    <div className={"flex flex-col w-max h-72 mt-36 "}>
      <div>
        <TimeBarContainer
          experiences={activeExperienceList?.filter(
            (details) => !details.isProject
          )}
        />
        <div
          className={"bg-gray-500 h-2 relative"}
          style={{
            width: baseTimeLine[lastYear],
          }}
        >
          {timeLineHighlight.map((positionFrame, index) => (
            <div
              key={index}
              className={"absolute top-0 bottom-0 bg-red-400 text-xs"}
              style={{
                left: positionFrame[0],
                width: _.max([
                  positionFrame[1] - positionFrame[0],
                  MIN_HIGHLIGHT_WIDTH,
                ]),
              }}
            />
          ))}
        </div>
      </div>
      <Band
        items={Object.keys(baseTimeLine).map((year, index) => (
          <TimeTag key={year} time={year} />
        ))}
        margin={Object.values(baseTimeLine)}
      />
      <Band
        items={activeProjectList?.map((details) => (
          <DetailView
            experience={details}
            isUpper={false}
            onClick={() => setHighlight(details)}
            key={details.id}
            width={PROJECT_WIDTH}
            margin={PROJECT_MARGIN}
          />
        ))}
        margin={projectPositions}
      />
    </div>
  );
}

export default TimeLine;

export { Band, TimeTag };
