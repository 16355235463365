import { useTimeLineContext } from "../context/TimeLineContext";

function dateToX(width, min, max, d) {
  const time = Number(max) - Number(min);
  const diff = Number(d) - Number(min);
  return (diff / time) * width;
}

const useTimeLine = () => {
  const { baseTimeLine } = useTimeLineContext();

  const timeFrameToTimeLine = (timeFrame) => {
    const startPos = dateToTimeLine(timeFrame.started);
    const endPos = dateToTimeLine(timeFrame.ended);
    return [startPos, endPos];
  };

  const dateToTimeLine = (date) => {
    const year = date.getFullYear();
    const yearWidth = baseTimeLine[year + 1] - baseTimeLine[year];

    const yearPositionX = dateToX(
      yearWidth,
      new Date(year, 0, 1),
      new Date(year + 1, 0, 1),
      date
    );
    return baseTimeLine[year] + yearPositionX; // add the x of the year
  };

  return { timeFrameToTimeLine, dateToTimeLine };
};

export { useTimeLine, dateToX };
